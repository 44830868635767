body {
  background-image: linear-gradient(0deg, #000428, 40%, #004e92);
  background-size: cover;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  width: 100vw;
  overflow-y: overlay;
  background-attachment: fixed;
}

a {
  text-decoration: none;
}

:root {
  --main-bg: #161b22;
  --main-txt: #eee;
  --border-color: #d0d7de;
  --primary-color: blue;
}
