.container {
  position: absolute;
  top: 100px;
  width: 100vw;
  height: calc(100vh - 100px);
  background-color: rgba(0, 0, 0, 0.493);

  i {
    color: var(--main-txt);
    font-size: 160%;
    position: absolute;
    right: 8%;

    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }

  form {
    background-color: var(--main-bg);
    color: var(--main-txt);
    border-radius: 6px;
    border: 1px solid var(--border-color);
    width: 380px;
    height: 440px;
    margin: 0 auto;

    .heading {
      text-align: center;
      margin-top: 20px;
      font-size: 150%;
      font-weight: 600;
    }

    .input {
      display: flex;
      flex-direction: column;
      margin: 14px auto;
      width: 80%;

      input {
        height: 30px;
        border-radius: 8px;
        padding-left: 10px;
        font-size: 100%;
        letter-spacing: 1px;
        background-color: var(--main-bg);
        border: 1px solid var(--border-color);
        color: var(--main-txt);

        &:focus,
        &:focus-visible {
          outline: none;
          border: 1px solid var(--primary-color);
          box-shadow: 0 0 20px var(--primary-color);
        }
      }
    }

    .btn {
      text-align: center;
      margin: 10px 0;

      button {
        padding: 8px 50px;
        font-size: 100%;
        border-radius: 6px;
        background-color: var(--main-bg);
        color: var(--main-txt);
        border: 1px solid var(--border-color);

        &:hover {
          background-color: var(--main-txt);
          color: var(--main-bg);
        }
      }
    }

    .error {
      color: red;
      text-align: center;
      margin-top: 20px;
    }
  }
}
