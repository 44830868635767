
@mixin keyfram($name) {
    @keyframes #{$name} {
        @content;
    }
}

@mixin animation($content) {
    animation: $content;
}

@include keyfram(spin) {
    to {
        transform: rotate(360deg);
    }
}

.container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.212);

    .div1 {
        width: 50px;
        height: 50px;
        border: 4px solid blue;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        position: absolute;
        top: 50%;
        left: 50%;
        border-radius: 50%;
        @include animation(spin 0.8s infinite)
    }

    .div2 {
        width: 42px;
        height: 42px;
        border: 4px solid yellow;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        position: absolute;
        top: calc(50% + 4px);
        left: calc(50% + 4px);
        border-radius: 50%;
        @include animation(spin 1s infinite)
    }

    .div3 {
        width: 34px;
        height: 34px;
        border: 4px solid red;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        position: absolute;
        top: calc(50% + 8px);
        left: calc(50% + 8px);
        border-radius: 50%;
        @include animation(spin 0.9s infinite)
    }
   
}