* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  height: 100vh;
}

button, input {
  border: none;
}

button {
  cursor: pointer;
}
