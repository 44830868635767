.tag {
  background-color: transparent;
  border: 1px solid var(--border-color);
  margin: 10px 20px;
  color: var(--main-txt);
  width: 320px;
  border-radius: 6px;
  display: flex;
  min-height: 50px;

  a {
    flex: 9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 8px;

    h3 {
      color: var(--main-txt);
    }

    p {
      font-size: 80%;
      color: #eee;
      width: 90%;
    }

    &:hover {
      h3 {
        color: var(--primary-color);
      }
    }
  }

  .btn {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    font-size: 90%;

    .edit {
      color: rgb(167, 198, 245);
    }

    .delete {
      color: rgb(221, 99, 99);
    }

    i {
      text-align: center;

      &:hover {
        opacity: 0.7;
        cursor: pointer;
      }
    }
  }
}
