.homeContent {
  p {
    font-size: 255%;
    color: var(--main-txt);
    width: 60%;
    margin: 40px auto;
    text-align: center;
  }

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;

    & > div {
      display: flex;
      flex-direction: column;
      color: var(--main-txt);
      font-size: 2.4rem;
    }

    & > span {
      margin: 0 30px;
      font-size: 2rem;
      color: var(--main-txt);
    }
  }

  hr {
    width: 60%;
    margin: 0 auto;
  }
}
