.btn {
  padding: 10px 30px;
  width: 90%;
  max-width: 600px;
  text-align: center;
  background-color: transparent;
  color: var(--main-txt);
  border: 1px solid var(--border-color);
  border-radius: 6px;
  margin: 20px auto;
  font-size: 1.1rem;
  font-weight: 500;

  &:hover {
    box-shadow: 0 3px var(--primary-color);
    opacity: 0.7;
    cursor: pointer;
  }
}
