.container {
  background-color: var(--main-bg);
  color: var(--main-txt);
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  header {
    display: flex;
    height: 40%;
    line-height: 100%;
    width: 80%;
  }
}

.btn {
  display: flex;
}

.signIn,
.signUp,
.logOut {
  height: 100%;
  padding: 6px 16px;
  font-size: 1.2rem;
  border: 1px solid var(--border-color);
}

.signIn {
  background-color: var(--main-bg);
  color: var(--main-txt);

  &:hover {
    background-color: rgba(255, 255, 255, 0.082);
  }
}

.signUp {
  margin-left: 4px;
  background-color: var(--main-txt);
  color: var(--main-bg);

  &:hover {
    background-color: rgba(255, 255, 255, 0.74);
  }
}

.searchBox {
  margin-right: 40px;
  flex: 3;
  display: flex;
  position: relative;
  height: 100%;
  margin-left: 5px;
  max-width: 100%;

  input {
    flex: 4;
    background-color: inherit;
    color: var(--main-txt);
    border: 1px solid var(--border-color);
    padding-left: 10px;
    letter-spacing: 2px;
    font-size: 16px;

    &:focus,
    &:focus-visible {
      outline: none;
      box-shadow: 0 0 6px var(--primary-color);
      border: 1px solid var(--primary-color);
    }
  }

  select {
    position: absolute;
    top: 50%;
    right: 120px;
    transform: translateY(-50%);
    border: none;
    font-size: 16px;
    background-color: inherit;
    color: var(--main-txt);
    width: 60px;
    letter-spacing: 1px;

    &:focus-visible {
      outline: none;
    }

    option {
      background-color: var(--main-bg);
    }
  }

  button {
    margin-left: 4px;
    padding: 8px 16px;
    background-color: var(--main-bg);
    color: var(--main-txt);
    border: 1px solid var(--border-color);
  }
}

.logOut {
  flex: 1;
  font-weight: 500;
  margin-right: 5px;
}

@media screen and (max-width: 500px) {
  .container {
    height: 124px;
    align-items: center;

    header {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 80px;
      margin-left: 0;
    }

    .searchBox {
      margin: 0 auto;
    }

    select {
      right: 100px;
    }

    .logOut {
      margin-top: 8px;
      padding: 4px 36px;
    }
  }
}
